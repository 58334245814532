import { Component } from "react";
import { Tooltip } from "react-tooltip";
import ComponentSVG from "../../../../../components/ui/typography/ComponentSVG";

interface IButtonSVGTooltip {
  btnID: string;
  btnClassName: string;
  dataBsToggle?: string;
  onClick: (e: any) => void;
  tooltipID: string;
  tooltipContent: string;
  tooltipPlace?: "top" | "left" | "right" | "bottom";
  tooltipClassName?: string;
  tooltipStyle?: any;
  SVGPath: React.ElementType;
  SVGSpanClassName?: string;
  SVGClassName?: string;
  isDiabled?: boolean;
}

const ButtonSVGTooltip: React.FC<IButtonSVGTooltip> = ({
  btnID,
  btnClassName,
  dataBsToggle = "",
  onClick,
  tooltipID,
  tooltipContent,
  tooltipPlace,
  tooltipClassName,
  tooltipStyle,
  SVGPath = Component,
  SVGSpanClassName,
  SVGClassName,
  isDiabled = false,
}: IButtonSVGTooltip) => {
  return (
    <button
      className={btnClassName}
      type="button"
      id={btnID}
      data-bs-toggle={dataBsToggle}
      onClick={onClick}
      disabled={isDiabled}
    >
      <Tooltip
        anchorId={tooltipID}
        content={tooltipContent}
        place={tooltipPlace}
        variant="light"
        className={tooltipClassName}
        style={tooltipStyle}
      />

      <ComponentSVG className={SVGSpanClassName}>
        <SVGPath className={SVGClassName} />
      </ComponentSVG>
    </button>
  );
};

export default ButtonSVGTooltip;
