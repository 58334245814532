import { decimalNumber } from "helpers/Functions";
import { SYMBOLS } from "../../../../../helpers/const";
import Image from "../mobileViewComponent/Image";

type TPPPWrapper = {
  eventRecord: Record<string, any>;
};

const PPPWrapper = ({ eventRecord }: TPPPWrapper) => {
  let pngImagePath = "/media/png/";

  return eventRecord?.ppeIsOpen ? (
    <div className="PPEViewFields">
      <div className="flex border-r w-[2.5rem] min-h-[1.9375rem] sticky left-0 z-50 checkbox_td bg-inherit">
        <label className="relative w-full h-full z-100 bg-inherit"></label>
      </div>
      <div className="PPEFieldView">
        <div className="PPEToggle">
          <span>PPP</span>
          <div className="flex group pl-[.3125rem] pr-2 ">
            <Image
              path={`${pngImagePath}info-gray.png`}
              className="w-[.875rem] h-[.875rem] group-hover:hidden"
              alt=""
            />
            <Image
              path={`${pngImagePath}info-purple.png`}
              className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
              alt=""
            />
          </div>
          <span className="PPEToggleResponde">
            {eventRecord?.price_per_exchange ? "Yes" : "No"}
          </span>
        </div>
        <div className="singleMKTPrice">
          <div className="label">
            TX Network
            <div className="flex group pl-1">
              <Image
                path={`${pngImagePath}info-gray.png`}
                className="w-[.875rem] h-[.875rem] group-hover:hidden"
                alt=""
              />
              <Image
                path={`${pngImagePath}info-purple.png`}
                className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                alt=""
              />
            </div>
          </div>
          <div
            className="seventeen-col"
            data-sybmol-attribute={SYMBOLS[eventRecord?.sell_price_currency]}
          >
            {typeof eventRecord?.sell_price === "object"
              ? eventRecord?.sell_price?.value
              : decimalNumber(eventRecord?.sell_price)}
          </div>
        </div>
        {eventRecord?.ppePartners?.map((partner: any) => {
          if (partner?.name && !partner?.is_hidden) {
            return (
              <div className="singleMKTPrice">
                <div className="label">
                  {partner?.name}
                  {partner?.name === "TxTrade" && (
                    <div className="flex group pl-1">
                      <Image
                        path={`${pngImagePath}info-gray.png`}
                        className="w-[.875rem] h-[.875rem] group-hover:hidden"
                      />
                      <Image
                        path={`${pngImagePath}info-purple.png`}
                        className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="seventeen-col"
                  data-sybmol-attribute={SYMBOLS[partner?.value?.symbol]}
                >
                  {partner?.value?.value}
                </div>
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PPPWrapper;
